.MainDash {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

@media screen and (max-width: 1200px) {
    .MainDash {
        justify-content: flex-start;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .MainDash {
        align-items: center;
    }
}